import { useState, createRef } from 'react';
import { initializeApp } from 'firebase/app';
import { addDoc, collection, getFirestore } from 'firebase/firestore';
import ReCAPTCHA from 'react-google-recaptcha';
import InputMask from 'react-input-mask';

import {
    Button,
    Col,
    Container,
    FloatingLabel,
    Form,
    Row,
    Toast,
} from 'react-bootstrap';

interface EmailFormState {
    name: string;
    email: string;
    phone: string;
    subject: string;
    message: string;
    contact_date: Date;
    closed: boolean;
}

interface ToastState {
    showToast: boolean;
    toastMessage: string;
    toastVariant: 'success' | 'danger';
}

export const Contact = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [subject, setSubject] = useState('1: Avaliação Neuropsicológica');
    const [message, setMessage] = useState('');
    const [showToast, setShowToast] = useState(false);
    const [toastVariant, setToastVariant] = useState('');
    const [toastMessage, setToastMessage] = useState('');
    const [validated, setValidated] = useState(false);
    const [statusButton, setButtonStatus] = useState(true);
    const [captchaToken, setToken] = useState('');
    const inputRef = createRef<InputMask>();

    const firebaseConfig = {
        apiKey: 'AIzaSyDCZffBUa8mXlhoBJ4x8O4WaPWEi9ZpEg8',
        authDomain: 'reliance-homepage-6c62d.firebaseapp.com',
        projectId: 'reliance-homepage-6c62d',
        storageBucket: 'reliance-homepage-6c62d.appspot.com',
        messagingSenderId: '707996035985',
        appId: '1:707996035985:web:48657769f518a902ad8830',
        measurementId: 'G-NSNM39SVGK',
    };

    const siteKey = '6LchyNkmAAAAAOJjFVCBmiaZyw2VYeew2buMcdo-'; //'6LejL9wmAAAAAEcb-7lT8-DMnEfJy8Zq2ZvHBdCs';
    const app = initializeApp(firebaseConfig);
    const db = getFirestore(app);
    const contacts = collection(db, 'contacts');

    const emailData: EmailFormState = {
        name: name,
        email: email,
        phone: phone,
        subject: subject,
        message: message,
        contact_date: new Date(),
        closed: false,
    };

    const toastState: ToastState = {
        showToast: false,
        toastMessage: '',
        toastVariant: 'success',
    };

    const handleNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setName(event.target.value);
    };

    const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value);
    };

    const handlePhoneChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPhone(event.target.value);
    };

    const handleSubjectChange = (
        event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
        const idx = event.target.options.selectedIndex;

        setSubject(event.target.value + ': ' + event.target.options[idx].text);
    };

    const handleMessageChange = (
        event: React.ChangeEvent<HTMLTextAreaElement>,
    ) => {
        setMessage(event.target.value);
    };

    const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
        const form = event.currentTarget;

        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        } else {
            emailData.contact_date = new Date();
            event.preventDefault();
            addContact(emailData);
        }

        setValidated(true);
    };

    const closeToast = () => {
        setShowToast(false);
        setToastVariant('success');
        setToastMessage('');
    };

    const showSuccessToast = () => {
        setShowToast(true);
        setToastVariant('success');
        setToastMessage(
            'Sua mensagem foi enviada com sucesso! Em breve entraremos em contato. Obrigado.',
        );
        setTimeout(closeToast, 10000);
    };

    const showErrorToast = (error: any) => {
        setShowToast(true);
        setToastVariant('danger');
        setToastMessage('Erro ao enviar o email: ' + error.message);
        setTimeout(closeToast, 10000);
    };

    const addContact = async (data: any) => {
        await addDoc(contacts, data)
            .then(() => {
                showSuccessToast();
                clearForm();
                setButtonStatus(true);
            })
            .catch((reason) => showErrorToast(reason));
    };

    const handleRecaptchaChange = (response: any) => {
        setToken(response);
        setButtonStatus(false);
    };

    const handleRecaptchaExpired = () => {
        setButtonStatus(true);
        setToken('');
    };

    const clearForm = () => {
        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        setSubject('1: Avaliação Neuropsicológica');
    };

    return (
        <Container fluid id="contact" className="overlay1">
            <Container>
                <Row>
                    <Col className="section-title">
                        <p className="display-5 title">Entre em Contato</p>
                    </Col>
                </Row>
                <Row>
                    <Col></Col>
                    <Col>
                        <Form
                            onSubmit={handleSubmit}
                            validated={validated}
                            noValidate
                        >
                            <Form.Group className="mb-3" controlId="formName">
                                <FloatingLabel
                                    controlId="floatingName"
                                    label={'Nome'}
                                >
                                    <Form.Control
                                        type="text"
                                        placeholder={'Informe o Nome'}
                                        onChange={handleNameChange}
                                        value={name}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formEmail">
                                <FloatingLabel
                                    controlId="floatingEmail"
                                    label={'Email'}
                                >
                                    <Form.Control
                                        type="email"
                                        placeholder={'Informe o Email'}
                                        value={email}
                                        onChange={handleEmailChange}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className="mb-3" controlId="formPhone">
                                <FloatingLabel
                                    controlId="floatingPhone"
                                    label={'Telefone'}
                                >
                                    <Form.Control
                                        as={InputMask}
                                        ref={inputRef}
                                        mask="(99) 99999-9999"
                                        placeholder="(00) 00000-0000"
                                        value={phone}
                                        onChange={handlePhoneChange}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formSubject"
                            >
                                <FloatingLabel
                                    controlId="floatingSubject"
                                    label={'Assunto'}
                                >
                                    <Form.Select
                                        placeholder="Selecione o assunto"
                                        onChange={handleSubjectChange}
                                    >
                                        <option value={1}>
                                            Avaliação Neuropsicológica
                                        </option>
                                        <option value={2}>
                                            Supervisão Clínica
                                        </option>
                                    </Form.Select>
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group
                                className="mb-3"
                                controlId="formMessage"
                            >
                                <FloatingLabel
                                    controlId="floatingMessage"
                                    label={'Mensagem'}
                                >
                                    <Form.Control
                                        as={'textarea'}
                                        aria-multiline={true}
                                        rows={3}
                                        placeholder={'Digite a mensagem'}
                                        draggable={false}
                                        value={message}
                                        onChange={handleMessageChange}
                                        required
                                    />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group>
                                <Row>
                                    <Col>
                                        <ReCAPTCHA
                                            sitekey={siteKey}
                                            onChange={handleRecaptchaChange}
                                            onExpired={handleRecaptchaExpired}
                                        ></ReCAPTCHA>
                                    </Col>
                                    <Col className="align-self-center justify-content-center">
                                        <Button
                                            type="submit"
                                            disabled={statusButton}
                                        >
                                            Enviar
                                        </Button>
                                    </Col>
                                </Row>
                            </Form.Group>
                        </Form>
                    </Col>
                </Row>
            </Container>
            <Toast
                show={showToast}
                onClose={closeToast}
                delay={10000}
                className="position-absolute bottom-0 end-0"
                autohide
            >
                <Toast.Header
                    closeButton={false}
                    className={
                        toastVariant === 'success'
                            ? 'toast-success'
                            : 'toast-error'
                    }
                >
                    <strong className="mr-auto">
                        {toastVariant === 'success' ? 'Sucesso' : 'Erro'}
                    </strong>
                </Toast.Header>
                <Toast.Body>{toastMessage}</Toast.Body>
            </Toast>
        </Container>
    );
};
