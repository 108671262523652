import React, { Component } from 'react';
import { Map, GoogleApiWrapper } from 'google-maps-react';

interface MapContainerProps {
    google: any;
}

interface MapContainerState {
    markerPosition: { lat: number; lng: number } | null;
}

class MapContainer extends Component<MapContainerProps, MapContainerState> {
    mapRef: React.RefObject<any> = React.createRef();
    marker: any;

    state = { markerPosition: null };

    constructor(props: MapContainerProps) {
        super(props);

        this.state = {
            markerPosition: null,
        };
    }

    componentDidMount() {
        const { google } = this.props;
        const geocoder = new google.maps.Geocoder();
        const geocodeRequest = {
            address:
                'R. das Camélias, 326 - Conj. Hab. Inocente Vila Nova Júnior, Maringá - PR, 87060-020',
            region: 'BR',
        };

        geocoder.geocode(geocodeRequest, (results: any, status: any) => {
            if (status === 'OK') {
                const location = results[0].geometry.location;
                const markerPosition = {
                    lat: location.lat(),
                    lng: location.lng(),
                };

                this.setState({ markerPosition }, () => {
                    this.addMarkerToMap();
                });
            } else {
                console.error('Erro na geolocalização', status);
            }
        });
    }

    addMarkerToMap() {
        const { google } = this.props;
        const markerPosition = this.state.markerPosition;
        const map = this.mapRef.current.map;

        if (map && markerPosition) {
            map.zoom = 14;
            this.marker = new google.maps.Marker({
                position: markerPosition,
                map: map,
                title: 'Localização',
            });
        }
    }

    render() {
        const { google } = this.props;
        const markerPosition = this.state.markerPosition;
        const mapStyles = {
            width: '100%',
            height: '400px',
            position: 'relative',
        };
        const containerStyles = {
            position: 'relative',
        };

        return (
            <Map
                google={google}
                style={mapStyles}
                ref={this.mapRef}
                containerStyle={containerStyles}
                centerAroundCurrentLocation={false}
                center={markerPosition}
            />
        );
    }
}
export default GoogleApiWrapper({
    apiKey: 'AIzaSyDCZffBUa8mXlhoBJ4x8O4WaPWEi9ZpEg8',
    libraries: ['places'],
})(MapContainer);
