import { Card, Col, Container, Figure, Row } from 'react-bootstrap';

export const Services = () => {
    return (
        <Container fluid id="services" className="overlay1">
            <Container>
                <Row>
                    <Col className="section-title">
                        <span className="display-5 title">Serviços</span>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Figure className="text-center">
                            <Figure.Caption>
                                <p className="h4">
                                    Nossos serviços podem ajudar os pacientes
                                    fornecendo avaliações precisas, intervenções
                                    personalizadas e suporte emocional,
                                    melhorando a qualidade de vida, a
                                    funcionalidade cognitiva e a saúde mental.
                                    Além disso, esses serviços podem ajudar
                                    outros profissionais da área fornecendo
                                    conhecimentos especializados, orientações
                                    clínicas e aprimoramento profissional,
                                    contribuindo para uma prática clínica mais
                                    eficaz e informada.
                                </p>
                            </Figure.Caption>
                        </Figure>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Card>
                            <Card.Body>
                                <h5 className="card-title">
                                    Avaliação Neuropsicológica
                                </h5>
                                <p className="card-text">
                                    A avaliação neuropsicológica é um processo
                                    utilizado para investigar o funcionamento
                                    cognitivo, emocional e comportamental de um
                                    indivíduo, identificando padrões de
                                    desempenho, habilidades preservadas e
                                    dificuldades específicas. Por meio de testes
                                    e técnicas padronizadas, o neuropsicólogo
                                    avalia diferentes áreas cognitivas, como
                                    memória, atenção, linguagem, raciocínio e
                                    funções executivas, com o objetivo de obter
                                    uma compreensão abrangente das capacidades
                                    mentais do paciente.
                                </p>
                                <p className="card-text">
                                    As aplicações da avaliação neuropsicológica
                                    são amplas e abrangem diversos contextos.
                                    Ela é fundamental no diagnóstico e no
                                    monitoramento de transtornos neurológicos,
                                    como doenças neurodegenerativas, traumatismo
                                    cranioencefálico, acidente vascular cerebral
                                    e distúrbios do desenvolvimento. Além disso,
                                    a avaliação neuropsicológica desempenha um
                                    papel importante na identificação de
                                    dificuldades de aprendizagem, transtornos de
                                    déficit de atenção e hiperatividade (TDAH),
                                    transtornos do espectro autista, entre
                                    outros. Essa avaliação também auxilia no
                                    planejamento de intervenções terapêuticas,
                                    adaptações escolares, orientação familiar e
                                    tomada de decisões clínicas, contribuindo
                                    para o desenvolvimento de estratégias de
                                    tratamento personalizadas e eficazes.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                    <Col>
                        <Card>
                            <Card.Body>
                                <h5 className="card-title">Supervisão</h5>
                                <p className="card-text">
                                    A supervisão em neuropsicologia é um
                                    processo no qual um profissional experiente
                                    na área orienta e apoia outros profissionais
                                    em seu trabalho clínico. Através da
                                    supervisão, os profissionais têm a
                                    oportunidade de discutir casos clínicos,
                                    revisar teorias, aprofundar conhecimentos e
                                    receber feedback sobre sua prática. A
                                    supervisão em neuropsicologia oferece
                                    diversos benefícios, incluindo o
                                    aprimoramento das habilidades clínicas, a
                                    atualização de conhecimentos, a melhoria na
                                    tomada de decisões clínicas, a discussão de
                                    desafios profissionais e o desenvolvimento
                                    de uma abordagem reflexiva e ética.
                                </p>
                                <p className="card-text">
                                    Os benefícios da supervisão em
                                    neuropsicologia se estendem tanto aos
                                    profissionais supervisionados quanto aos
                                    pacientes. Através da supervisão, os
                                    profissionais têm a oportunidade de
                                    aprimorar suas competências técnicas e
                                    clínicas, aumentar sua confiança e segurança
                                    na prática e obter orientações valiosas para
                                    lidar com situações complexas. Isso resulta
                                    em uma melhor qualidade de atendimento aos
                                    pacientes, com uma abordagem mais informada,
                                    atualizada e eficaz. Além disso, a
                                    supervisão também promove a troca de
                                    experiências e a construção de uma
                                    comunidade profissional sólida, na qual os
                                    profissionais podem compartilhar
                                    conhecimentos, se apoiar mutuamente e
                                    crescer profissionalmente.
                                </p>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};
