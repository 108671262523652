import { useState } from 'react';
import { Button, Card, Col, Modal } from 'react-bootstrap';

export const CardProfessionalsModal = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Col>
            <Card>
                <Card.Body>
                    <div className="card-img-top" id="card-img-03"></div>
                    <h5 className="card-title">Supervisão Neuropsicológica</h5>
                    <p className="card-text">
                        Procura por aprimoramento profissional ou quer trocar
                        experiências na discussão de casos mais complexos?
                    </p>
                    <Button
                        as="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalProfessionals"
                        onClick={handleShow}
                    >
                        Saiba mais...
                    </Button>
                </Card.Body>
            </Card>

            <Modal
                className="modal fade"
                id="modalProfessionals"
                data-bs-backdrop="static"
                aria-labelledby="modalProfessionalsLabel"
                aria-hidden="false"
                tabIndex="-1"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Dialog className="modal-dialog">
                    <Modal.Header className="modal-header">
                        <Modal.Title
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            Profissionais e neuropsicólogos
                        </Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <Card>
                            <Card.Img id="card-img-inner-03"></Card.Img>
                            <Card.Body>
                                <p className="card-text">
                                    As supervisões neuropsicológicas oferecem
                                    suporte e orientação a outros profissionais
                                    da área, permitindo a aquisição de
                                    conhecimentos especializados e o
                                    aprimoramento das habilidades em avaliação e
                                    intervenção neuropsicológica. Os benefícios
                                    incluem a melhoria da prática clínica, a
                                    atualização de conhecimentos, a discussão de
                                    casos complexos, a troca de experiências e a
                                    oportunidade de aprimoramento profissional.
                                </p>
                                <p className="card-text">
                                    As supervisões são conduzidas por um
                                    neuropsicólogo experiente, que orienta e
                                    assessora outros profissionais. São
                                    abordados casos clínicos, discussões
                                    teóricas, revisão de instrumentos de
                                    avaliação, análise de resultados e
                                    estratégias de intervenção. A abordagem é
                                    colaborativa, visando ao desenvolvimento das
                                    competências dos profissionais
                                    supervisionados.
                                </p>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        </Col>
    );
};
