import { useState } from 'react';
import { Button, Card, Col, Modal } from 'react-bootstrap';

export const CardOlderModal = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Col>
            <Card>
                <Card.Body>
                    <div className="card-img-top" id="card-img-01"></div>
                    <h5 className="card-title">Adultos e idosos</h5>
                    <p className="card-text">
                        Saiba quais são os benefícios e abordagens praticadas no
                        atendimento de idosos e adultos.
                    </p>
                    <Button
                        as="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalOlder"
                        onClick={handleShow}
                    >
                        Saiba mais...
                    </Button>
                </Card.Body>
            </Card>

            <Modal
                className="modal fade"
                id="modalOlder"
                data-bs-backdrop="static"
                aria-labelledby="modalOlderLabel"
                aria-hidden="false"
                tabIndex="-1"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Dialog>
                    <Modal.Header>
                        <Modal.Title id="exampleModalLabel">
                            Atendimento de adultos e idosos
                        </Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        ></button>
                    </Modal.Header>
                    <Modal.Body>
                        <Card>
                            <Card.Img id="card-img-inner-01"></Card.Img>
                            <Card.Body>
                                <p className="card-text">
                                    O atendimento neuropsicológico para adultos
                                    e idosos visa avaliar e intervir em questões
                                    relacionadas ao envelhecimento cerebral,
                                    como problemas de memória, habilidades
                                    cognitivas, planejamento e tomada de
                                    decisões. Os benefícios incluem melhorar a
                                    qualidade de vida, promover a autonomia,
                                    ajudar no manejo de doenças
                                    neurodegenerativas, como Alzheimer, e
                                    auxiliar na adaptação a mudanças funcionais
                                    e emocionais.
                                </p>
                                <p className="card-text">
                                    São utilizadas avaliações neuropsicológicas
                                    para identificar deficiências cognitivas
                                    específicas e compreender o impacto na vida
                                    diária do paciente. Com base nos resultados,
                                    são desenvolvidos planos de intervenção
                                    personalizados, que podem incluir técnicas
                                    de estimulação cognitiva, estratégias
                                    compensatórias, treinamento de habilidades e
                                    apoio emocional. A abordagem é centrada na
                                    pessoa e visa preservar e otimizar suas
                                    habilidades cognitivas.
                                </p>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        </Col>
    );
};
