import {
    Card,
    Col,
    Container,
    Figure,
    Nav,
    NavLink,
    Navbar,
    Row,
} from 'react-bootstrap';
import MapContainer from './MapContainer';
import { BsInstagram, BsTwitter, BsFacebook, BsWhatsapp } from 'react-icons/bs';

export const Where = (map: any) => {
    return (
        <Container fluid className="background-01">
            <Container className="container-white">
                <Container id="where">
                    <Row>
                        <Col className="section-title">
                            <p className="display-5 title">Onde Estamos</p>
                        </Col>
                    </Row>
                    <div className="row-md-2">
                        <Col>
                            <MapContainer />
                        </Col>
                        <Col>
                            <Row id="endereco">
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <h5 className="card-title">
                                                Nosso Consultório
                                            </h5>
                                            <p className="card-text text-center">
                                                Estamos localizados na cidade de
                                                Maringá, no Paraná.
                                            </p>
                                            <p className="card-text text-center">
                                                Edifício Camélias - Rua das
                                                Camélias, 326 - Zona 5
                                            </p>
                                            <p className="card-text text-center">
                                                Telefone:{' '}
                                                <a
                                                    href="https://wa.me/5544991761946"
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                >
                                                    (44) 99176-1946
                                                </a>
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                            <Row id="social-media">
                                <Col>
                                    <Card>
                                        <Card.Body>
                                            <h5 className="card-title">
                                                Nossas Redes Sociais
                                            </h5>
                                            <p className="card-text text-center">
                                                Você também nos encontra nas
                                                redes sociais.
                                            </p>
                                            <Container>
                                                <Row>
                                                    <Col>
                                                        <Nav>
                                                            <Navbar>
                                                                <NavLink
                                                                    href="https://www.instagram.com/reliance_neuropsicologia/"
                                                                    target="_blank"
                                                                    title="Reliance Neuropsicologia no Instagram"
                                                                >
                                                                    <BsInstagram id="instagram" />
                                                                </NavLink>
                                                                <NavLink
                                                                    href="https://www.facebook.com/relianceneuropsicologia/"
                                                                    target="_blank"
                                                                    title="Reliance Neuropsicologia no Facebook"
                                                                >
                                                                    <BsFacebook id="facebook" />
                                                                </NavLink>
                                                                <NavLink
                                                                    href="https://twitter.com/RelianceContato/"
                                                                    target="_blank"
                                                                    title="Reliance Neuropsicologia no Twitter"
                                                                >
                                                                    <BsTwitter id="twitter" />
                                                                </NavLink>
                                                                <NavLink
                                                                    href="https://wa.me/5544991761946"
                                                                    target="_blank"
                                                                    title="Reliance Neuropsicologia no WhatsApp"
                                                                >
                                                                    <BsWhatsapp id="whatsapp" />
                                                                </NavLink>
                                                            </Navbar>
                                                        </Nav>
                                                    </Col>
                                                </Row>
                                            </Container>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </div>
                </Container>
            </Container>
        </Container>
    );
};
