import React, { StrictMode } from 'react';

import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

import { Header } from './components/header/Header';
import { Home } from './components/home/Home';
import { Neuro } from './components/neuro/Neuro';
import { Services } from './components/services/Services';
import { Where } from './components/where/Where';
import { Contact } from './components/contact/contact';
import { Footer } from './components/footer/Footer';
import { Media } from './components/media/Media';

function App() {
    const firebaseConfig = {
        apiKey: 'AIzaSyDCZffBUa8mXlhoBJ4x8O4WaPWEi9ZpEg8',
        authDomain: 'reliance-homepage-6c62d.firebaseapp.com',
        projectId: 'reliance-homepage-6c62d',
        storageBucket: 'reliance-homepage-6c62d.appspot.com',
        messagingSenderId: '707996035985',
        appId: '1:707996035985:web:48657769f518a902ad8830',
        measurementId: 'G-NSNM39SVGK',
    };

    const app = initializeApp(firebaseConfig);
    const analytics = getAnalytics(app);

    return (
        <div className="App">
            <Header />
            <Home />
            <Neuro />
            <Media />
            <Services />
            <Where />
            <Contact />
            <Footer />
        </div>
    );
}

export default App;
