import { Container, Figure, Nav, NavLink, Navbar } from 'react-bootstrap';
import { BsFacebook, BsInstagram, BsTwitter, BsWhatsapp } from 'react-icons/bs';

export const Footer = () => {
    return (
        <Container fluid id="footer">
            <Container>
                <Figure className="text-center w-100">
                    <Figure.Caption>
                        <Nav>
                            <Navbar>
                                <NavLink
                                    href="https://www.instagram.com/reliance_neuropsicologia/"
                                    target="_blank"
                                    title="Reliance Neuropsicologia no Instagram"
                                >
                                    <BsInstagram id="instagram" />
                                </NavLink>
                                <NavLink
                                    href="https://www.facebook.com/relianceneuropsicologia/"
                                    target="_blank"
                                    title="Reliance Neuropsicologia no Facebook"
                                >
                                    <BsFacebook id="facebook" />
                                </NavLink>
                                <NavLink
                                    href="https://twitter.com/RelianceContato/"
                                    target="_blank"
                                    title="Reliance Neuropsicologia no Twitter"
                                >
                                    <BsTwitter id="twitter" />
                                </NavLink>
                                <NavLink
                                    href="https://wa.me/5544991761946"
                                    target="_blank"
                                    title="Reliance Neuropsicologia no WhatsApp"
                                >
                                    <BsWhatsapp id="whatsapp" />
                                </NavLink>
                            </Navbar>
                        </Nav>
                    </Figure.Caption>
                </Figure>
                <div className="text-center w-100">
                    <p>
                        Reliance Neuropsicologia - Todos os Direitos Reservados
                    </p>
                </div>
            </Container>
        </Container>
    );
};
