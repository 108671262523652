import { Container } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import '@fortawesome/fontawesome-svg-core/styles.css';

export const Header = () => {
    return (
        <Container fluid>
            <header>
                <Navbar className="fixed-top" expand="lg">
                    <Container fluid>
                        <Navbar.Toggle aria-controls="basic-navbar-nav">
                            <FontAwesomeIcon icon={faBars} />
                        </Navbar.Toggle>
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="me-auto">
                                <Nav.Link href="#home">Home</Nav.Link>
                                <Nav.Link href="#neuro">
                                    Neuropsicologia
                                </Nav.Link>
                                <Nav.Link href="#media">Notícias</Nav.Link>
                                <Nav.Link href="#services">
                                    Nossos Serviços
                                </Nav.Link>
                                <Nav.Link href="#where">Onde estamos</Nav.Link>
                                <Nav.Link href="#contact">Contato</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </header>
        </Container>
    );
};
