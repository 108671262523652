import { useState } from 'react';
import { Button, Card, Col, Modal } from 'react-bootstrap';

export const CardChildrenModal = () => {
    const [show, setShow] = useState(false);
    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    return (
        <Col>
            <Card>
                <Card.Body>
                    <div className="card-img-top" id="card-img-02"></div>
                    <h5 className="card-title">Crianças e adolescentes</h5>
                    <p className="card-text">
                        Quais são os benefícios e abordagens da avaliação
                        neuropsicológica em crianças e adolescentes.
                    </p>
                    <Button
                        as="button"
                        className="btn btn-primary"
                        data-bs-toggle="modal"
                        data-bs-target="#modalChildren"
                        onClick={handleShow}
                    >
                        Saiba mais...
                    </Button>
                </Card.Body>
            </Card>

            <Modal
                className="modal fade"
                id="modalChildren"
                data-bs-backdrop="static"
                aria-labelledby="modalChildrenLabel"
                aria-hidden="false"
                tabIndex="-1"
                show={show}
                onHide={handleClose}
                centered
            >
                <Modal.Dialog className="modal-dialog">
                    <Modal.Header className="modal-header">
                        <Modal.Title
                            className="modal-title"
                            id="exampleModalLabel"
                        >
                            Atendimento de crianças e adolescentes
                        </Modal.Title>
                        <button
                            type="button"
                            className="btn-close"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={handleClose}
                        ></button>
                    </Modal.Header>
                    <Modal.Body className="modal-body">
                        <Card>
                            <Card.Img id="card-img-inner-02"></Card.Img>
                            <Card.Body>
                                <p className="card-text">
                                    Benefícios: O atendimento neuropsicológico
                                    para crianças e adolescentes busca avaliar e
                                    intervir em dificuldades de aprendizagem,
                                    déficits de atenção, transtornos do
                                    desenvolvimento, problemas de comportamento
                                    e questões emocionais. Os benefícios incluem
                                    melhorar o desempenho acadêmico, facilitar o
                                    desenvolvimento social e emocional, aumentar
                                    a autoestima e promover o bem-estar geral.
                                </p>
                                <p className="card-text">
                                    Processos e abordagens: São utilizadas
                                    avaliações neuropsicológicas para investigar
                                    o funcionamento cognitivo, emocional e
                                    comportamental da criança ou adolescente.
                                    Com base nos resultados, são implementadas
                                    intervenções personalizadas, que podem
                                    envolver técnicas de treinamento cognitivo,
                                    orientação familiar, estratégias de
                                    organização e gerenciamento do tempo, além
                                    de intervenções psicoterapêuticas
                                    específicas para questões emocionais. A
                                    abordagem é adaptada à idade e às
                                    necessidades individuais da criança ou
                                    adolescente.
                                </p>
                            </Card.Body>
                        </Card>
                    </Modal.Body>
                </Modal.Dialog>
            </Modal>
        </Col>
    );
};
