import { Col, Container, Figure, Row } from 'react-bootstrap';
import { CardOlderModal } from './CardOlderModal';
import { CardChildrenModal } from './CardChildrenModal';
import { CardProfessionalsModal } from './CardProfessionalsModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faQuoteLeft, faQuoteRight } from '@fortawesome/free-solid-svg-icons';

export const Neuro = () => {
    return (
        <Container fluid className="background-01">
            <Container className="container-white">
                <Container id="neuro">
                    <Row>
                        <Col className="section-title">
                            <span className="display-5 title">
                                Neuropsicologia
                            </span>
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Figure className="text-end">
                                <Figure.Caption className="blockquote-footer">
                                    <FontAwesomeIcon icon={faQuoteLeft} />
                                    <cite title="Source Title">
                                        A neuropsicologia estuda as conexões
                                        entre o cérebro e o comportamento
                                        humano, analisando como o funcionamento
                                        cerebral influencia habilidades
                                        cognitivas, emocionais e
                                        comportamentais.
                                    </cite>
                                    <FontAwesomeIcon icon={faQuoteRight} />
                                </Figure.Caption>
                            </Figure>
                        </Col>
                    </Row>
                    <Row>
                        <CardOlderModal />
                        <CardChildrenModal />
                        <CardProfessionalsModal />
                    </Row>
                </Container>
            </Container>
        </Container>
    );
};
