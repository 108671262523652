import { Carousel, Container, Image } from 'react-bootstrap';
import bg01 from '../../assets/images/bg01.jpg';
import bg02 from '../../assets/images/bg02.jpg';
import bg03 from '../../assets/images/bg03.jpg';

export const Home = () => {
    return (
        <Container fluid id="home" className="overlay1">
            <Carousel id="carouselHome" fade controls={false}>
                <Carousel.Item>
                    <Image
                        src={bg01}
                        fluid
                        className="opacity-25 image-item "
                        alt="Avaliação Neuropsicológica"
                        title="Avaliação Neuropsicológica"
                    />
                    <Carousel.Caption>
                        <h1 className="display-1">MARINGÁ</h1>
                        <h2 className="display-4">e região</h2>
                        <h2 className="display-5">
                            Faça sua Avaliação Neuropsicológica
                        </h2>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                        src={bg02}
                        fluid
                        className="opacity-25 image-item"
                        alt="Supervisão"
                        title="Supervisão"
                    />
                    <Carousel.Caption>
                        <h1 className="display-1">Neuropsicólogos</h1>
                        <h2 className="display-5">
                            Fazemos supervisão em neuropsicologia
                        </h2>
                    </Carousel.Caption>
                </Carousel.Item>
                <Carousel.Item>
                    <Image
                        src={bg03}
                        fluid
                        className="opacity-25 image-item"
                        alt="Online e Presencial"
                        title="Online e Presencial"
                    />
                    <Carousel.Caption>
                        <h1 className="display-1">
                            Avaliação Neuropsicológica
                        </h1>
                        <h2 className="display-5">Online e Presencial</h2>
                    </Carousel.Caption>
                </Carousel.Item>
            </Carousel>
        </Container>
    );
};
