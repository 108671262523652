import { Col, Container, Row } from 'react-bootstrap';

export const Media = () => {
    return (
        <Container fluid className="background-02">
            <Container id="media">
                <Row>
                    <Col className="section-title">
                        <span className="display-5 title">
                            Vídeos, Notícias e Eventos
                        </span>
                    </Col>
                </Row>
            </Container>
        </Container>
    );
};
